import React from "react";
import { Container, MenuButtonGatsbyLink } from "@util/standard";
import styled from "styled-components";
import { Maybe, SanityBlogs } from "@graphql-types";

interface Props {
  blogCard?: Maybe<SanityBlogs> | undefined;
  smallSize?: boolean;
  isPress?: boolean;
}

const ImageWrapper = styled.div<{
  backgroundUrl?: string;
  smallSize?: boolean;
}>`
  height: ${props => (props.smallSize ? "210px" : "270px")};
  width: 100%;

  display: block;
  justify-content: center;
  ${({ backgroundUrl }) =>
    backgroundUrl && `background: url(${backgroundUrl})`};
  background-position: center;
  background-size: cover;
`;

const StyledContainer = styled(Container)`
  border-radius: 10px;
  overflow: hidden;
`;

const BlogCard = (props: Props) => {
  const { blogCard, smallSize, isPress } = props;

  if (blogCard == undefined || blogCard == null) {
    return null;
  }

  return (
    <StyledContainer
      width={smallSize ? "335px" : "430px"}
      mobileWidth="100%"
      mobileMargin="0px auto 25px auto"
      border
    >
      {!!blogCard.featureImage && !!blogCard.featureImage.asset && (
        <ImageWrapper
          backgroundUrl={blogCard.featureImage.asset.url as string}
          smallSize={smallSize}
        ></ImageWrapper>
      )}

      <Container
        backgroundColor="white"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding="30px 20px"
        height={smallSize ? "auto" : "450px"}
        mobileHeight="fit-content"
      >
        <Container>
          <p>{blogCard.publishdate}</p>
          <h3>{blogCard.title}</h3>
          <p>{blogCard.authorName}</p>
          <p> {blogCard.excerpt}</p>
        </Container>
        {blogCard._rawSlug && !!blogCard?.sanityInternal ? (
          <MenuButtonGatsbyLink
            to={
              isPress
                ? `/press-news/${blogCard._rawSlug.current}`
                : `/blog/${blogCard._rawSlug.current}`
            }
            width="fit-content"
            padding="10px 35px 10px 35px"
            margin="0px"
            isPink
          >
            Read Press Release
          </MenuButtonGatsbyLink>
        ) : blogCard.link ? (
          <MenuButtonGatsbyLink
            width="fit-content"
            padding="10px 35px 10px 35px"
            margin="0px"
            to={!!blogCard.link.url ? blogCard.link.url : ""}
            isPink
          >
            {blogCard.link.linktext}
          </MenuButtonGatsbyLink>
        ) : null}
      </Container>
    </StyledContainer>
  );
};

export default BlogCard;
