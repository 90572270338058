import { useBlogPageQuery, useBlogsQuery } from "@api";
import BlogCard from "@components/pages-components/blog/blogCard";
import FeaturedBlog from "@components/shared/featuredBlogPost";
import { colors } from "@util/constants";
import { Container, MenuButton } from "@util/standard";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlogsSEO from "../components/pages-components/blog/seo";
import LayOut from "@components/layout";
import { navigate } from "@reach/router";

const StyledA = styled.a<{ isSelected?: boolean }>`
  color: black;
  padding-left: 8px;
  padding-right: 8px;
  font-family: "header bold";

  ${({ isSelected }) =>
    isSelected &&
    `border-bottom: 3px;
  border-color: ${colors.pink};
  border-style: solid;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  height: 25px;
  color: black;`};

  &:hover {
    border-bottom: 3px;
    border-color: ${colors.pink};
    border-style: solid;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    height: 25px;
    color: black;
  }
`;

const Blog = () => {
  const { sanityBlogPage } = useBlogPageQuery();
  const { allSanityBlogs } = useBlogsQuery();

  const [shownNumbers, setShownNumbers] = useState(6);

  const [filter, setFilter] = useState("all");
  const [filteredData, setFilteredData] = useState(allSanityBlogs.edges);

  useEffect(() => {
    if (filter === "blog" || filter === "caseStudies") {
      const filtered = allSanityBlogs.edges.filter(
        node => node.node.blogType === filter
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(allSanityBlogs.edges);
    }
  }, [filter]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const search = urlParams.get("search");

    if (search == "all") {
      setFilter("all");
    }

    if (search == "blog") {
      setFilter("blog");
    }
  }, []);

  return (
    <>
      <BlogsSEO />
      <Container margin="100px 0px 0px 0px" />
      {sanityBlogPage && sanityBlogPage.featuredBlog && (
        <FeaturedBlog featuredBlogPost={sanityBlogPage.featuredBlog} />
      )}
      <LayOut>
        <Container
          backgroundColor="babyBlue"
          width="100%"
          display="flex"
          flexDirection="column"
          paddingBottom={70}
        >
          <Container
            width="375px"
            margin="40px auto 30px auto"
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            mobileWidth="95%"
          >
            <StyledA
              isSelected={filter === "all"}
              onClick={() => {
                setFilter("all");
              }}
            >
              All
            </StyledA>

            <StyledA
              isSelected={filter === "blog"}
              onClick={() => {
                setFilter("blog");
              }}
            >
              Blog
            </StyledA>
            <StyledA
              isSelected={filter === "pressReleases"}
              onClick={() => {
                navigate("/press-news");
              }}
            >
              Press & News
            </StyledA>
          </Container>
          <Container
            maxWidth="1000px"
            margin="auto"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            mobileWidth="95%"
            gridGap="70px"
            mobileGridGap="0px"
          >
            {filteredData.slice(0, shownNumbers).map(({ node: blog }) => {
              return <BlogCard
                key={blog._id}
                blogCard={blog}
                isPress={blog.blogType === "pressRelease"}
              />
            })}
          </Container>
          {filteredData.length > shownNumbers && (
            <MenuButton
              width="fit-content"
              padding="10px 35px 10px 35px"
              onClick={() => {
                setShownNumbers(pre => pre + 4);
              }}
              margin="40px auto"
            >
              View more
            </MenuButton>
          )}
        </Container>
      </LayOut>
    </>
  );
};

export default Blog;
